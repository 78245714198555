import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FooterDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Machile learning models are just as good their</Subheading>}
        heading="Data"
        description={"and we provide best people to help with your training data"}
        buttonRounded={false}
        primaryButtonText=""
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <Features
        subheading={<Subheading>-</Subheading>}
        heading="Get trained and skilled team"
        description={"to create, train, support and validate your machine learning models with training data for as low as $50/day"}
        cards={[
          {
            imageSrc: ShieldIconImage,
            title: "1 million +",
            description: "labels processed todate"
          },
          {
            imageSrc: FastIconImage,
            title: "10 + ",
            description: "member strong and trained team"
          },
          { imageSrc: ReliableIconImage, title: "Affordable", 
            description : "with cost by target volume or per person model"
          }
          
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};

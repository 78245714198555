import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/Home_TwoColumnWithInput";
import Features from "components/features/Home_3ColServices";
import MainFeature from "components/features/Home_TwoColWithButtons";
import MainFeature2 from "components/features/Home_TwoColTwoSubs";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/Home_GetStarted";
import Footer from "components/footers/FooterDark";
import heroScreenshotImageSrc from "images/professional-illustration.svg";
import macHeroScreenshotImageSrc from "images/stats-illustration.svg";
import prototypeIllustrationImageSrc from "images/stats-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";


import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>-</Subheading>}
        heading="Our personalised data management services"
        description={"to train, support and validate your machine learning programs"}
        cards={[
          { imageSrc: CustomizeIconImage, title: "Data labelling",
            description : "to train your machine learning models",
          },
          { imageSrc: SimpleIconImage, title: "Data Integration",
            description : "for loading, transforming your data sets"
          },
          { imageSrc: SimpleIconImage, title: "Power BI",
            description : "for all your reports and dashboards"
          }, 
        ]}
        linkText=""
      />
      <MainFeature
        subheading={<Subheading>Data Labelling, Validation</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <MainFeature2
        subheading={<Subheading>Data Integration and Power BI</Subheading>}
        heading={
          <>
            for <HighlightedText>Reporting and Insights</HighlightedText>
          </>
        }
        description = "We don't just stop with data, we connect your datasets and build valuable reporting and insights too"
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "for the best and quality professional services as any others",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "as we worked for corporates too once",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
